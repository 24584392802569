import React, { useEffect, useState } from "react";
import {
  GoodsExpectedReadyApi,
  GoodsExpectedReadyPreviewApi,
} from "../../../../Services/OurLocationChildService";
import { BLOCKS, MARKS, INLINES } from "@contentful/rich-text-types";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { withRouter } from "react-router-dom";
import { Row, Container, Col } from "react-bootstrap";
import { useSelector } from "react-redux";
import "../../../../assets/mobirise/css/mbr-additional.css";
import "../../../../assets/theme/css/style.css";
import Top from "../../../../utils/Top/Top";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import classes from "../../OurLocations.module.css";
import { useLocation, Link, NavLink } from "react-router-dom";
import { PageTitle } from "../../../../utils/common";

const GoodsExpectedReady = (props) => {
  let location = useLocation();
  const url = useSelector((state) => state.url);
  const [data, setData] = useState(null);
  const handleLink = (id, url) => {
    props.history.push({
      pathname: url,
      state: { id: id },
    });
  };
  const HEADING_1 = ({ children }) => (
    <h1 className="mbr-section-title mbr-fonts-style mbr-black mb-3 display-5">
      {children}
    </h1>
  );
  const HEADING_2 = ({ node, children }) => (
    <h2 className="mbr-fonts-style mbr-black mb-3 display-5">{children}</h2>
  );
  const HEADING_3 = ({ children }) => (
    <h3 className="mbr-fonts-style mbr-black mb-3 text-center display-7">
      {children}
    </h3>
  );

  const options = {
    renderNode: {
      [BLOCKS.HEADING_1]: (node, children) => <HEADING_1>{children}</HEADING_1>,
      [BLOCKS.HEADING_2]: (node, children) => <HEADING_2>{children}</HEADING_2>,
      [BLOCKS.HEADING_3]: (node, children) => <HEADING_3>{children}</HEADING_3>,
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <p className={paragraphClass(node)}>{children}</p>
      ),
      [INLINES.ENTRY_HYPERLINK]: (node, children) => {
        return (
          <a
            onClick={() =>
              handleLink(node.data.target.sys.id, "/ContactUs/Denmark")
            }
            style={{ textDecoration: "none" }}
          >
            {children}
          </a>
        );
      },
      [INLINES.ASSET_HYPERLINK]: (node, children) => {
        return (
          <a
            href={`${node?.data?.target?.fields?.file?.url}`}
            target="_blank"
            style={{ textDecoration: "none" }}
          >
            {children}
          </a>
        );
      },
      [INLINES.HYPERLINK]: (node, children) => {
        return (
          <a
            href={`${node?.data?.uri}`}
            target="_blank"
            download
            style={{ textDecoration: "none" }}
          >
            {children}
          </a>
        );
      },
    },
  };
  function paragraphClass(node) {
    const className = "mbr-fonts-style mbr-text mbr-white mb-3 display-7";
    return className;
  }

  useEffect(async () => {
    let getData;
    const queryParams = new URLSearchParams(location.search);
    const val = queryParams.get("v") || "";
    if (val == "preview") {
      getData = await GoodsExpectedReadyPreviewApi(url);
    } else {
      getData = await GoodsExpectedReadyApi(url);
    }
    PageTitle(getData?.Title ?? "");
    setData(getData);
  }, []);

  let display = null,
    path = [];

  if (data) {
    display = data?.Description?.content?.map((info, id) => {
      if (id > 0) return documentToReactComponents(info, options);
    });
    path = data?.Path.split("/");
    path = path.slice(0, path.length - 1);
  }

  return (
    <>
      {data?.PageImages?.length > 0 && (
        <Top
          pageTitle={data?.PageTitle}
          images={data?.PageImages}
          title={data?.Title}
        />
      )}
      <section
        className={
          classes.sectionContent +
          "   text-left content5 centeradd cid-szWdSGiEXu"
        }
      >
        <Container>
          <Row>
            <Col
              xs="12"
              md="12"
              className={data?.PageImages?.length > 0 ? "" : ""}
            >
              <div className={classes.breadcrumb + " mb-4"}>
                <div>
                  <NavLink to="/">HOME</NavLink>
                  <span className={classes.bread + " me-1 ms-1"}>
                    &gt;
                  </span>{" "}
                  {path?.map((m) => {
                    let pth = data?.Path.substring(
                      0,
                      data?.Path.indexOf(m) + m.length
                    );
                    return (
                      <>
                        <NavLink to={"/" + pth}>
                          {m.replace(/-/g, " ").toUpperCase()}
                        </NavLink>

                        <span className={classes.bread + " me-1 ms-1"}>
                          &gt;
                        </span>
                      </>
                    );
                  })}
                  {data?.Title}
                </div>
              </div>
              <h1 className="mbr-section-title mbr-fonts-style mbr-black mb-3 text-bold display-5">
                {data?.PageTitle}
              </h1>
              {display}
            </Col>
          </Row>

          <Row>
            <Col md={12}>
              <TableContainer component={Paper}>
                <Table aria-label="customized table">
                  <TableHead>
                    <TableRow>
                      <TableCell>BLL</TableCell>
                      <TableCell>Arrival list</TableCell>
                      <TableCell>
                        {new Date().toLocaleDateString("sv-SE", {
                          weekday: "long",
                        })}
                      </TableCell>
                      <TableCell>{new Date().getDate()}</TableCell>
                      <TableCell>
                        {new Date().toLocaleString("default", {
                          month: "long",
                        })}
                      </TableCell>
                      <TableCell>{new Date().getFullYear()}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell rowSpan={6}>No data found</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default withRouter(GoodsExpectedReady);
