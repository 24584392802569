import React, { useState } from "react";
import classes from "./Slider.module.css";
import { Row, Container, Col } from "react-bootstrap";
import Carousel from "react-bootstrap/Carousel";
import "../../assets/theme/css/main.css";

export default function Slider(props) {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };
  return (
    <section className={classes.slider_carousel}>
      <Container fluid className="p-0">
        <Row>
          <Col xs={12} className="p-0">
            <Carousel
              activeIndex={index}
              controls={false}
              onSelect={handleSelect}
            >
              {props.data
                ? props.data?.map((info, id) => {
                    return (
                      <Carousel.Item className={classes.sliderItem}>
                        <img
                          alt=""
                          className={classes.sliderImage}
                          src={"https:" + info?.fields?.file?.url}
                        />
                        <Carousel.Caption></Carousel.Caption>
                      </Carousel.Item>
                    );
                  })
                : ""}
            </Carousel>
          </Col>
        </Row>
      </Container>
    </section>
  );
}
