import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { searchApi } from "../../Services/SearchService";
import classes from "./SearchPage.module.css";
import { Row, Container, Col } from "react-bootstrap";
import SearchIcon from "@mui/icons-material/Search";
import { withRouter, NavLink } from "react-router-dom";
import { BLOCKS, MARKS, INLINES } from "@contentful/rich-text-types";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { documentToPlainTextString } from "@contentful/rich-text-plain-text-renderer";
const Search = (props) => {
  const [searchcontent, setsearchContent] = useState([]);
  const url = useSelector((state) => state.url);
  useEffect(async () => {
    try {
      let response = await searchApi(url);

      if (response.status == 200 && response.statusText == "OK") {
        setsearchContent(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  }, []);
  const [searchtext, setSearchText] = useState("");
  const [displayContent, setDisplayContent] = useState([]);

  // const options = {
  //   renderNode: {
  //     [INLINES.ASSET_HYPERLINK]: (node, children) => {
  //       return (
  //         <a
  //           href={`${node.data.target?.fields?.file?.url}`}
  //           target="_blank"
  //           download
  //         >
  //           {children}
  //         </a>
  //       );
  //     },
  //   },

  //   [BLOCKS.EMBEDDED_ASSET]: (node, children) => {
  //     // render the EMBEDDED_ASSET as you need
  //     // console.log("node : ",node);
  //     return (
  //       <img
  //         src={`https://${node.data.target?.fields?.file?.url}`}
  //         alt={node.data.target.fields.description}
  //       />
  //     );
  //   },
  // };
  const options = {
    renderNode: {
      [INLINES.ENTRY_HYPERLINK]: (node, children) => {
        console.log(node);
        // return (
        //   <NavLink
        //     to={{
        //       // pathname: "/locations/Sweden/" + node.data.target.fields.slug,
        //       aboutProps: {
        //         id: node.data.target.sys.id,
        //       },
        //     }}
        //     style={{ textDecoration: "none" }}
        //   >
        //     {children}
        //   </NavLink>
        // );
        console.log("node : ", node);
      },
      [INLINES.ASSET_HYPERLINK]: (node, children) => {
        return (
          <a
            href={`${node?.data?.target?.fields?.file?.url}`}
            target="_blank"
            download
            style={{ textDecoration: "none" }}
          >
            {children}
          </a>
        );
      },
      [INLINES.HYPERLINK]: (node, children) => {
        return (
          <a
            href={`${node?.data?.uri}`}
            target="_blank"
            download
            style={{ textDecoration: "none" }}
          >
            {children}
          </a>
        );
      },
    },
  };

  const handleSearchText = (event) => {
    setSearchText(event.target.value);
    handleSearch(event.target.value);
  };

  const handleEnter = (event) => {
    if (event.key == "Enter") {
      handleSearch(searchtext);
    }
  };

  const handleSearch = (search) => {
    let content = [];
    let searchstring = search?.toLowerCase();
    console.log("searchstring : ", searchstring);
    searchcontent?.map((info) => {
      var titlelower = null;
      var pageTitleLower = null;
      var description = null;
      let contains = false;
      titlelower =
        info?.fields?.title != null
          ? info?.fields?.title?.toLowerCase()
          : info?.title?.toLowerCase();
      pageTitleLower =
        info?.fields?.pageTitle != null
          ? info?.fields?.pageTitle?.toLowerCase()
          : info?.pageTitle?.toLowerCase();
      description = info?.fields?.text;

      if (titlelower?.includes(searchstring) && searchstring.length > 0) {
        contains = true;
        console.log("contains title : ", info);
      }
      if (pageTitleLower?.includes(searchstring) && searchstring.length > 0) {
        contains = true;
        console.log("contains page title : ");
      }

      let text = description?.content?.map((data) => {
        let desc = documentToPlainTextString(data);
        desc = desc.toLowerCase();
        if (desc?.includes(searchstring) && searchstring.length > 0) {
          contains = true;
        }
        return desc;
      });
      //  info?.fields?.subMenu?.map((data) => {

      //   titleSub = data?.fields?.title?.toLowerCase();
      //   console.log(data,"hkask")
      //   pageTitleSub = data?.fields?.pageTitle?.toLowerCase();
      //   descSub = data?.fields?.text;

      //   if (titleSub?.includes(searchstring) && searchstring.length > 0) {
      //     contains = true;
      //     console.log("contains title : ",info);
      //     content.push(info)
      //   }
      //   if (pageTitleSub?.includes(searchstring) && searchstring.length > 0) {
      //     contains = true;
      //     console.log("contains page title : ");
      //   }

      // });

      if (contains) {
        content.push(info);
      }
    });
    setDisplayContent([...content]);
  };

  let displaySearchData = null;

  if (displayContent) {
    displaySearchData = displayContent.map((info, id) => {
      return (
        <div key={info + id} className={classes.eachresult}>
          <div
            className={classes.title + " mb-1"}
            onClick={(e) =>
              handleInternalContent(
                info?.fields?.path != null ? info?.fields?.path : info?.path,
                info?.sys
              )
            }
          >
            {info?.fields?.pageTitle != null
              ? info?.fields?.pageTitle
              : info?.pageTitle}
          </div>
          {info?.fields?.title != null ? (
            <div
              className={classes.description + " ms-2"}
              onClick={(e) =>
                handleInternalContent(info?.fields?.path, info?.sys)
              }
            >
              {info?.fields?.description?.content?.map((data, idx) => {
                return (
                  <div key={data + idx}>
                    {documentToReactComponents(data, options)}
                  </div>
                );
              })}
            </div>
          ) : (
            <div
              className={classes.title + " mb-1"}
              onClick={(e) =>
                handleInternalContent(
                  info?.fields?.path != null ? info?.fields?.path : info?.path,
                  info?.sys
                )
              }
            >
              {info?.fields?.title != null ? info?.fields?.title : info?.title}
            </div>
          )}
          <hr />
        </div>
      );
    });
  }

  const handleInternalContent = (path, id) => {
    props.history.push({
      pathname: path != null ? "/" + path : "/",
    });
  };

  return (
    <section
      className={"text-center  text-left content5 centeradd cid-szWdSGiEXu"}
    >
      <Container>
        <Row className="text-center">
          <Col md={12}>
            <div className={classes.searchpage}>
              <div className={classes.inputflex}>
                <input
                  type="text"
                  placeholder="Search here"
                  value={searchtext}
                  onChange={handleSearchText}
                  onKeyDown={handleEnter}
                  className={classes.input}
                />
                <SearchIcon
                  className={classes.search}
                  onClick={() => handleSearch(searchtext)}
                />
              </div>
              <div className={classes.result + " mt-4"}>
                <div className={"col-9 ms-2"}>
                  {displaySearchData?.length > 0 ? (
                    <div className={classes.heading + " mb-3"}>
                      Search Results
                    </div>
                  ) : (
                    <div className={classes.heading + " mb-3"}>
                      No Search Results!
                    </div>
                  )}
                  <div>{displaySearchData}</div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default withRouter(Search);
