import axios from "axios";

export const DenmarkApi = async (url) => {
  let response = await axios.get(url + "/getPage/OurLocations/Denmark");
  return response.data;
};
export const CopenhagenApi = async (url) => {
  let response = await axios.get(
    url + "/getPage/OurLocations/Denmark/Copenhagen"
  );
  return response.data;
};
export const DenmarkServicesApi = async (url) => {
  let response = await axios.get(
    url + "/getPage/OurLocations/Denmark/Services"
  );
  return response.data;
};
export const StockholmServicesApi = async (url) => {
  let response = await axios.get(
    url + "/getPage/OurLocations/Sweden/Arlanda/Services"
  );
  return response.data;
};
export const CopenhagenHandlingAirlinesApi = async (url) => {
  let response = await axios.get(
    url + "/getPage/OurLocations/Denmark/Copenhagen/AirlinesHandeledCopenhagen"
  );
  return response.data;
};
export const CopenhagenFacilitiesApi = async (url) => {
  let response = await axios.get(
    url + "/getPage/OurLocations/Denmark/Copenhagen/Facilities"
  );
  return response.data;
};
export const CopenhagenLocationApi = async (url) => {
  let response = await axios.get(
    url + "/getPage/OurLocations/Denmark/Copenhagen/Location"
  );
  return response.data;
};
export const CopenhagenHandlingTimesApi = async (url) => {
  let response = await axios.get(
    url + "/getPage/OurLocations/Denmark/Copenhagen/HandlingTimes"
  );
  return response.data;
};
export const BillundApi = async (url) => {
  let response = await axios.get(url + "/getPage/OurLocations/Denmark/Billund");
  return response.data;
};
export const BillundHandlingTimesApi = async (url) => {
  let response = await axios.get(
    url + "/getPage/OurLocations/Denmark/Billund/HandlingTimes"
  );
  return response.data;
};
export const BillundLocationApi = async (url) => {
  let response = await axios.get(
    url + "/getPage/OurLocations/Denmark/Billund/Location"
  );
  return response.data;
};
export const GoodsExpectedReadyApi = async (url) => {
  let response = await axios.get(
    url + "/getPage/OurLocations/Denmark/Billund/GoodsExpectedReady"
  );
  return response.data;
};

export const BillundFacilitiesApi = async (url) => {
  let response = await axios.get(
    url + "/getPage/OurLocations/Denmark/Billund/Facilities"
  );
  return response.data;
};
export const SwedenApi = async (url) => {
  let response = await axios.get(url + "/getPage/OurLocations/Sweden");
  return response.data;
};

export const SwedenCargoPickUpApi = async (url) => {
  let response = await axios.get(url + "/getPage/OurLocations/Sweden/PUCPC");
  return response.data;
};

export const StockholmApi = async (url) => {
  let response = await axios.get(
    url + "/getPage/OurLocations/Sweden/Arlanda"
  );
  return response.data;
};
export const StockholmHandlingAirlinesApi = async (url) => {
  let response = await axios.get(
    url + "/getPage/OurLocations/Sweden/Arlanda/HandlingAirlines"
  );
  return response.data;
};
export const StockholmFacilitiesApi = async (url) => {
  let response = await axios.get(
    url + "/getPage/OurLocations/Sweden/Arlanda/Facilities"
  );
  return response.data;
};

export const NorwayApi = async (url) => {
  let response = await axios.get(url + "/getPage/OurLocations/Norway");
  return response.data;
};

// Preview APi
export const DenmarkPreviewApi = async (url) => {
  let response = await axios.get(url + "/getPage/preview/OurLocations/Denmark");
  return response.data;
};
export const CopenhagenPreviewApi = async (url) => {
  let response = await axios.get(
    url + "/getPage/preview/OurLocations/Denmark/Copenhagen"
  );
  return response.data;
};
export const DenmarkServicesPreviewApi = async (url) => {
  let response = await axios.get(
    url + "/getPage/preview/OurLocations/Denmark/Services"
  );
  return response.data;
};
export const StockholmServicesPreviewApi = async (url) => {
  let response = await axios.get(
    url + "/getPage/preview/OurLocations/Sweden/Arlanda/Services"
  );
  return response.data;
};
export const CopenhagenHandlingAirlinesPreviewApi = async (url) => {
  let response = await axios.get(
    url +
      "/getPage/preview/OurLocations/Denmark/Copenhagen/AirlinesHandeledCopenhagen"
  );
  return response.data;
};
export const CopenhagenFacilitiesPreviewApi = async (url) => {
  let response = await axios.get(
    url + "/getPage/preview/OurLocations/Denmark/Copenhagen/Facilities"
  );
  return response.data;
};
export const CopenhagenLocationPreviewApi = async (url) => {
  let response = await axios.get(
    url + "/getPage/preview/OurLocations/Denmark/Copenhagen/Location"
  );
  return response.data;
};
export const CopenhagenHandlingTimesPreviewApi = async (url) => {
  let response = await axios.get(
    url + "/getPage/preview/OurLocations/Denmark/Copenhagen/HandlingTimes"
  );
  return response.data;
};
export const BillundPreviewApi = async (url) => {
  let response = await axios.get(
    url + "/getPage/preview/OurLocations/Denmark/Billund"
  );
  return response.data;
};
export const BillundHandlingTimesPreviewApi = async (url) => {
  let response = await axios.get(
    url + "/getPage/preview/OurLocations/Denmark/Billund/HandlingTimes"
  );
  return response.data;
};
export const BillundLocationPreviewApi = async (url) => {
  let response = await axios.get(
    url + "/getPage/preview/OurLocations/Denmark/Billund/Location"
  );
  return response.data;
};
export const GoodsExpectedReadyPreviewApi = async (url) => {
  let response = await axios.get(
    url + "/getPage/preview/OurLocations/Denmark/Billund/GoodsExpectedReady"
  );
  return response.data;
};
export const BillundFacilitiesPreviewApi = async (url) => {
  let response = await axios.get(
    url + "/getPage/preview/OurLocations/Denmark/Billund/Facilities"
  );
  return response.data;
};
export const SwedenPreviewApi = async (url) => {
  let response = await axios.get(url + "/getPage/preview/OurLocations/Sweden");
  return response.data;
};

export const SwedenCargoPickUpPreviewApi = async (url) => {
  let response = await axios.get(
    url + "/getPage/preview/OurLocations/Sweden/PUCPC"
  );
  return response.data;
};

export const StockholmPreviewApi = async (url) => {
  let response = await axios.get(
    url + "/getPage/preview/OurLocations/Sweden/Arlanda"
  );
  return response.data;
};
export const StockholmHandlingAirlinesPreviewApi = async (url) => {
  let response = await axios.get(
    url + "/getPage/preview/OurLocations/Sweden/Arlanda/HandlingAirlines"
  );
  return response.data;
};
export const StockholmFacilitiesPreviewApi = async (url) => {
  let response = await axios.get(
    url + "/getPage/preview/OurLocations/Sweden/Arlanda/Facilities"
  );
  return response.data;
};

export const NorwayPreviewApi = async (url) => {
  let response = await axios.get(url + "/getPage/preview/OurLocations/Norway");
  return response.data;
};
