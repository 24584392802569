import React, { useEffect, useState } from "react";
import {
  DenmarkApi,
  DenmarkPreviewApi,
} from "../../../../Services/ContactChildService";
import Top from "../../../../utils/Top/Top";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { useDispatch } from "react-redux";
import classes from "../../../Contact/Contact.module.css";
import { Row, Container, Col } from "react-bootstrap";
import { useSelector } from "react-redux";
import "../../../../assets/mobirise/css/mbr-additional.css";
import "../../../../assets/theme/css/style.css";
import { childApi } from "../../../../Services/HeaderService";
import Slider from "../../../Slider/Slider";
import { BLOCKS } from "@contentful/rich-text-types";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import { useLocation, NavLink } from "react-router-dom";
import { PageTitle } from "../../../../utils/common";

const Denmark = () => {
  const Dispatch = useDispatch();
  let location = useLocation();
  const url = useSelector((state) => state.url);

  const [addressIcon, setAddressIcon] = useState(null);
  const [mailIcon, setMailIcon] = useState(null);
  const [phoneIcon, setPhoneIcon] = useState(null);
  const [title, setTitle] = useState(null);
  const [desc, setDesc] = useState(null);
  const [companyAddress, setCompanyAddress] = useState(null);
  const [mailingAddress, setMailingAddress] = useState(null);
  const [officeHour, setOfficeHour] = useState(null);
  const [customerSupport, setCustomerSupport] = useState(null);
  const [expanded, setExpanded] = React.useState("address1");

  const handleChange = (address) => (event, newExpanded) => {
    setExpanded(newExpanded ? address : false);
  };
  const [denmark, setDenmark] = useState(null);
  useEffect(async () => {
    let denmarkData;
    const queryParams = new URLSearchParams(location.search);
    const val = queryParams.get("v") || "";
    if (val == "preview") {
      denmarkData = await DenmarkPreviewApi(url);
    } else {
      denmarkData = await DenmarkApi(url);
    }
    setDenmark(denmarkData);
    PageTitle(denmarkData?.Title ?? "");
  }, []);
  const HEADING_1 = ({ children }) => (
    <h1 className="mbr-section-title mbr-fonts-style mbr-black mb-3 display-5 pb-3">
      {children}
    </h1>
  );
  const HEADING_2 = ({ node, children }) => (
    <h2 className="mbr-fonts-style mbr-black mb-3 text-center text-bold display-6">
      {children}
    </h2>
  );
  const HEADING_3 = ({ children }) => (
    <h3 className="mbr-fonts-style mbr-black mb-3 display-7">{children}</h3>
  );

  const options = {
    renderNode: {
      [BLOCKS.HEADING_1]: (node, children) => <HEADING_1>{children}</HEADING_1>,
      [BLOCKS.HEADING_2]: (node, children) => <HEADING_2>{children}</HEADING_2>,
      [BLOCKS.HEADING_3]: (node, children) => <HEADING_3>{children}</HEADING_3>,
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <p className={paragraphClass(node)}>{children}</p>
      ),
    },
  };
  function paragraphClass(node) {
    const className = "mbr-fonts-style mbr-text mbr-white mb-3 display-7";
    return className;
  }

  const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
  ))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
  }));

  const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
      expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
      {...props}
    />
  ))(({ theme }) => ({
    backgroundColor:
      theme.palette.mode === "dark"
        ? "rgba(255, 255, 255, .05)"
        : "rgba(0, 0, 0, .03)",
    flexDirection: "row-reverse",
    "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
      transform: "rotate(90deg)",
    },
    "& .MuiAccordionSummary-content": {
      marginLeft: theme.spacing(1),
    },
  }));

  const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: "1px solid rgba(0, 0, 0, .125)",
  }));

  let display = null,
    path = [];
  const apiCall = async (id) => {
    let data = await childApi(url, id);
    let descData = data?.description?.content?.map((info, id) => {
      return documentToReactComponents(info, options);
    });
    let titleData = data?.title;
    let companyAddressData = data?.companyAddress?.content?.map((info, id) => {
      return documentToReactComponents(info, options);
    });
    let mailingAddressData = data?.mailingAddress?.content?.map((info, id) => {
      return documentToReactComponents(info, options);
    });

    let officeHourData = data?.officeHours?.content?.map((info, id) => {
      return documentToReactComponents(info, options);
    });
    let customerSupportData = data?.customerSupport?.content?.map(
      (info, id) => {
        return documentToReactComponents(info, options);
      }
    );
    data?.images?.map((info, id) => {
      if (id == 0) {
        setAddressIcon(info?.fields?.file?.url);
      } else if (id == 1) {
        setMailIcon(info?.fields?.file?.url);
      } else {
        setPhoneIcon(info?.fields?.file?.url);
      }
    });
    setTitle(titleData);
    setDesc(descData);
    setCompanyAddress(companyAddressData);
    setMailingAddress(mailingAddressData);
    setOfficeHour(officeHourData);
    setCustomerSupport(customerSupportData);
  };

  if (denmark) {
    display = denmark?.Description?.content?.map((info, id) => {
      return documentToReactComponents(info, options);
    });
    path = denmark?.Path.split("/");
    path = path.slice(0, path.length - 1);
  }

  return (
    <>
      {denmark?.PageImages?.length > 0 && (
        <Top
          pageTitle={denmark?.PageTitle}
          images={denmark?.PageImages}
          title={denmark?.Title}
        />
      )}

      <section className={" text-left content5 centeradd cid-szWdSGiEXu"}>
        <Container>
          <Row>
            <Col
              xs="12"
              md="12"
              className={denmark?.PageImages?.length > 0 ? "" : ""}
            >
              <div className={classes.breadcrumb + " mb-4"}>
                <div>
                  <NavLink to="/">HOME</NavLink>
                  <span className={classes.bread + " me-1 ms-1"}>
                    &gt;
                  </span>{" "}
                  {path?.map((m) => {
                    let pth = denmark?.Path.substring(
                      0,
                      denmark?.Path.indexOf(m) + m.length
                    );
                    return (
                      <>
                        <NavLink to={"/" + pth}>
                          {m.replace(/-/g, " ").toUpperCase()}
                        </NavLink>

                        <span className={classes.bread + " me-1 ms-1"}>
                          &gt;
                        </span>
                      </>
                    );
                  })}
                  {denmark?.Title}
                </div>
              </div>
              {/* <h1 className="mbr-section-title mbr-fonts-style mbr-black mb-3 text-bold display-5">
                {denmark?.PageTitle}
              </h1> */}
              <div className="text-center">{display}</div>
            </Col>
          </Row>

          <Row>
            <Col md={12}>
              {denmark?.SubMenu?.map((info, id) => {
                return (
                  <Accordion
                    expanded={expanded === info.fields.title}
                    onChange={handleChange(info.fields.title)}
                  >
                    <AccordionSummary
                      id={info.fields.title + "d-header"}
                      onClick={() => apiCall(info.sys.id)}
                    >
                      <Typography>{info.fields.title}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Row className="mt-4">
                        {companyAddress ? (
                          <div className={"card col-md-4"}>
                            <div className={classes.cardImage}>
                              <img
                                className={"card-img-top " + classes.image}
                                src={"https:" + addressIcon}
                                // className={classes.image}
                                alt="Card image cap"
                              />
                            </div>

                            <div className="card-body">{companyAddress}</div>
                          </div>
                        ) : (
                          ""
                        )}

                        {customerSupport ? (
                          <div className="card col-md-4">
                            <div className={classes.cardImage}>
                              <img
                                className={"card-img-top " + classes.image}
                                src={"https:" + phoneIcon}
                                alt="Card image cap"
                              />
                            </div>

                            <div className="card-body">{customerSupport}</div>
                          </div>
                        ) : (
                          ""
                        )}
                        {mailingAddress ? (
                          <div className="card col-md-4">
                            <div className={classes.cardImage}>
                              <img
                                className={"card-img-top " + classes.image}
                                src={"https:" + mailIcon}
                                alt="Card image cap"
                              />
                            </div>

                            <div className="card-body">{mailingAddress}</div>
                          </div>
                        ) : (
                          ""
                        )}
                        {officeHour ? (
                          <section className="content7 cid-szWpH3l81p">
                            <Container>
                              <Row>
                                <Col md={12}>
                                  <blockquote className="link-no-underline">
                                    {officeHour}
                                  </blockquote>
                                </Col>
                              </Row>
                            </Container>
                          </section>
                        ) : (
                          ""
                        )}
                      </Row>
                    </AccordionDetails>
                  </Accordion>
                );
              })}
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Denmark;
