import React, { useEffect, useState } from "react";
import classes from "./Home.module.css";
import { homeApi, previewApi } from "../../Services/HomeService";
import { BLOCKS, MARKS } from "@contentful/rich-text-types";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { Link, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Row, Col, Container } from "react-bootstrap";
import { useSelector } from "react-redux";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { CardActionArea, CardActions } from "@mui/material";
import "../../assets/mobirise/css/mbr-additional.css";
import "../../assets/theme/css/style.css";
import "../../assets/theme/css/main.css";
import { PageTitle } from "../../utils/common";

const Home = (props) => {
  let location = useLocation();
  const Dispatch = useDispatch();
  const url = useSelector((state) => state.url);
  const [home, setHome] = useState(null);

  const styles = {
    media1: {
      height: 275,
      width: "100%",
      objectFit: "contain",
    },
    media2: {
      height: 275,
      width: "100%",
    },
  };

  const HEADING_1 = ({ children }) => (
    <h1 className="mbr-section-title mbr-fonts-style mbr-white mb-3 display-1">
      {children}
    </h1>
  );

  const options = {
    renderNode: {
      [BLOCKS.HEADING_1]: (node, children) => (
        <HEADING_1 className="">{children}</HEADING_1>
      ),
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <p className={paragraphClass(node)}>{children}</p>
      ),
    },
  };
  function paragraphClass(node) {
    const className = "mbr-fonts-style mbr-text mbr-white mb-3 display-7";
    return className;
  }

  useEffect(async () => {
    let homeData;
    const queryParams = new URLSearchParams(location.search);
    const val = queryParams.get("v") || "";
    if (val == "preview") {
      homeData = await previewApi(url);
    } else {
      homeData = await homeApi(url);
    }
    PageTitle(homeData?.Title);
    setHome(homeData);
  }, []);

  let desc,
    title,
    news_title,
    news_desc,
    sub_heading,
    pageTitle,
    displayLinks = null;

  if (home) {
    title = home.Description?.map((info, id) => {
      if (id == 0) return documentToReactComponents(info, options);
    });
    pageTitle = home?.PageTitle;

    desc = home?.Description?.map((info, id) => {
      if (id > 0) return documentToReactComponents(info, options);
    });
    news_desc = home?.News?.content?.map((info, id) => {
      if (id != 0) {
        return documentToReactComponents(info, options);
      }
    });
    news_title = home?.News?.content?.map((info, id) => {
      if (id == 0) {
        return documentToReactComponents(info, options);
      }
    });
    sub_heading = home?.SubHeading?.content?.map((info, id) => {
      return documentToReactComponents(info, options);
    });
    displayLinks = home?.Links?.map((info, id) => {
      return (
        <Col md={6} lg={4}>
          <Card className="text-center mb-5">
            <CardActionArea>
              <CardMedia
                component="img"
                style={id ? styles.media2 : styles.media1}
                image={"https:" + info.fields.image.fields.file.url}
              />
              <CardContent sx={{ minHeight: 150 }}>
                <Typography
                  gutterBottom
                  variant="h4"
                  component="div"
                  className="card-title"
                >
                  {info.fields.title}
                </Typography>
                <Typography className="mbr-black mbr-fonts-style mbr-text mb-3 display-7 text-center card-desc">
                  {info.fields.description?.content?.map((info, id) => {
                    return documentToReactComponents(info);
                  })}
                </Typography>
              </CardContent>
            </CardActionArea>
            <CardActions className="justify-content-center">
              <Link
                to={{
                  pathname: "/locations/" + info.fields.slug,
                }}
                className="btn btn-primary display-4"
              >
                Learn more
              </Link>
            </CardActions>
          </Card>
        </Col>
      );
    });
  }

  return (
    <>
      {/* <div>
        <Warning remove={handleRemoveWarning} show={warning} className="z-index"> 
          {displayWarning}
        </Warning>
      </div> */}
      <section
        className="cid-szWdkawVmG mbr-fullscreen mbr-parallax-background"
        style={{ backgroundImage: `url(https:${home?.Image})` }}
      >
        <div className="mbr-overlay"></div>
        <Container>
          <Row>
            <div className="content-wrap heading">
              {title}
              {desc}
            </div>
          </Row>
        </Container>
      </section>

      {/* <section className={"   text-left content5 centeradd cid-szWdSGiEXu text-center"}>
        <Container>
          <Row>
            <Col md={12}>
              <h1 className="mbr-section-title mbr-fonts-style mbr-black mb-3 display-5 pb-3">
                {pageTitle}
              </h1>
              {sub_heading}
            </Col>
          </Row>
        </Container>
      </section> */}
      {/* <section className=" text-center cid-szWdJmjpxZ section-bg">
        <Container>
          <Row>
            <Col md={12}>
              <h1 className="mbr-fonts-style mbr-white mb-3 display-5 ">
                Quick Tracking
              </h1>
              <p className="mbr-fonts-style mbr-text mbr-white text-center mb-5 display-7">
                <Link to="/Tracking" className="text-decoration-none">
                  Quick tracking only shows spirit system info
                </Link>
                &nbsp;and to&nbsp;
                <Link
                  to="/https://www.track-trace.com/aircargo"
                  className="text-decoration-none"
                >
                  &nbsp;Air cargo tracking - track-trace
                </Link>
              </p>
            </Col>
          </Row>
          <Row className="offset-md-1">
            <div className="d-flex flex-row flex-wrap ">
             
              <Col
                lg={5}
                sm={12}
                className={isMobile ? "w-100 mb-3 " : "offset-lg-3 "}
              >
                <TextForm
                  value={(val) => setNumber(val)}
                  label="Air WayBill Number"
                  isEmpty={
                    submit === false && number.length == 0 ? false : true
                  }
                  className={isMobile ? "w-100" : ""}
                  required
                  maxlength="11"
                  type="number"
                />
              </Col>
              <Col
                lg={2}
                sm={12}
                className={isMobile ? "w-100 mb-3" : "d-flex"}
              >
                <Button
                  type="button"
                  onClick={handleFetch}
                  className={
                    isMobile
                      ? "btn btn-black display-4"
                      : "btn btn-black border-right-lg btn-height border-right-lg display-4 justify-content-start"
                  }
                >
                  Track
                </Button>
              </Col>
            </div>
          </Row>
        </Container>
      </section> */}
      {/* <section className="features3 cid-szWdJmjpxZ">
        <Container>
          <Row className="text-center">
            <Col md={12}>
              <h1 className="mbr-section-title mbr-fonts-style mbr-white mb-3 display-5">
                Our Locations
              </h1>
            </Col>
          </Row>
          <Row className="mt-4">{displayLinks}</Row>
        </Container>
      </section> */}
      {/* <section className="content7 cid-szWpH3l81p">
        <Container>
          <Row>
            <Col md={12}>
              <blockquote className="link-no-underline">
                {news_title}
                {news_desc}
              </blockquote>
            </Col>
          </Row>
        </Container>
      </section> */}
    </>
  );
};

export default Home;
