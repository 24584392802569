import * as actions from "./actions";

const initialstate = {
  // url : "http://sasgroundhandling.sasuat.local:4000",
 url : "https://www.spiritaircargohandling.com",
  // url: "localhost:4003"
};

const Authreducer = (state = initialstate, action) => {
  switch (action.type) {
    case actions.Set_Data:
      state = {
        ...state,
        home: action.home,
      };
      break;
  }
  console.log("state : ", state);
  return state;
};

export default Authreducer;
