import React, { useEffect, useState } from "react";
import {
  BillundFacilitiesApi,
  BillundFacilitiesPreviewApi,
} from "../../../../Services/OurLocationChildService";
import { childApi, childPreviewApi } from "../../../../Services/HeaderService";
import { BLOCKS, MARKS, INLINES } from "@contentful/rich-text-types";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { withRouter } from "react-router-dom";
import { Row, Container, Col } from "react-bootstrap";
import { useSelector } from "react-redux";
import "../../../../assets/mobirise/css/mbr-additional.css";
import "../../../../assets/theme/css/style.css";
import classes from "../../OurLocations.module.css";
import Top from "../../../../utils/Top/Top";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useLocation, NavLink } from "react-router-dom";
import { PageTitle } from "../../../../utils/common";

const BillundFacilities = () => {
  let location = useLocation();
  const url = useSelector((state) => state.url);
  const [data, setData] = useState(null);
  const [table1, setTable1] = useState(null);
  const [table2, setTable2] = useState(null);

  useEffect(async () => {
    let billundFacilitiesData;
    const queryParams = new URLSearchParams(location.search);
    const val = queryParams.get("v") || "";
    if (val == "preview") {
      billundFacilitiesData = await BillundFacilitiesPreviewApi(url);
    } else {
      billundFacilitiesData = await BillundFacilitiesApi(url);
    }
    PageTitle(billundFacilitiesData?.Title ?? "");
    setData(billundFacilitiesData);
    billundFacilitiesData?.SubMenu?.map((info, id) => {
      if (id == 0) {
        Menu(info.fields?.facilitiesTable1, (res) => {
          setTable1(res);
        });
        Menu1(info.fields?.facilitiesTable2, (res) => {
          setTable2(res);
        });
      }
    });
  }, []);
  const HEADING_1 = ({ children }) => (
    <h1 className="mbr-section-title mbr-fonts-style mbr-black mb-3 display-5">
      {children}
    </h1>
  );
  const HEADING_2 = ({ node, children }) => (
    <h2 className="mbr-fonts-style mbr-black mb-3 display-5">{children}</h2>
  );
  const HEADING_3 = ({ children }) => (
    <h3 className="mbr-fonts-style mbr-black mb-3 text-center display-7">
      {children}
    </h3>
  );

  const createData = (terminal, value) => {
    return { terminal, value };
  };
  const createData1 = (value, yes, no) => {
    return { value, yes, no };
  };

  const Menu = (data, res) => {
    let menu = [];
    data?.map(async (value1, id) => {
      let getData;
      const queryParams = new URLSearchParams(location.search);
      const val = queryParams.get("v") || "";
      if (val == "preview") {
        getData = await childPreviewApi(url, value1.sys.id);
      } else {
        getData = await childApi(url, value1.sys.id);
      }
      let value = Object.values(getData);

      menu.push(createData(...value));
      if (menu.length == data.length) {
        res(menu);
      }
    });
  };
  const Menu1 = (data, res) => {
    let menu = [];
    data?.map(async (value1, id) => {
      let getData;
      const queryParams = new URLSearchParams(location.search);
      const val = queryParams.get("v") || "";
      if (val == "preview") {
        getData = await childPreviewApi(url, value1.sys.id);
      } else {
        getData = await childApi(url, value1.sys.id);
      }
      let value = Object.values(getData);

      menu.push(createData1(...value));
      if (menu.length == data.length) {
        res(menu);
      }
    });
  };

  const options = {
    renderNode: {
      [BLOCKS.HEADING_1]: (node, children) => <HEADING_1>{children}</HEADING_1>,
      [BLOCKS.HEADING_2]: (node, children) => <HEADING_2>{children}</HEADING_2>,
      [BLOCKS.HEADING_3]: (node, children) => <HEADING_3>{children}</HEADING_3>,
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <p className={paragraphClass(node)}>{children}</p>
      ),
    },
  };
  function paragraphClass(node) {
    const className =
      "mbr-fonts-style mbr-text text-left mbr-white mb-3 display-7";
    return className;
  }
  let display = null,
    path = [];

  if (data) {
    display = data?.Description?.content?.map((info, id) => {
      return documentToReactComponents(info, options);
    });
    path = data?.Path.split("/");
    path = path.slice(0, path.length - 1);
  }

  return (
    <>
      {data?.PageImages?.length > 0 && (
        <Top
          pageTitle={data?.PageTitle}
          images={data?.PageImages}
          title={data?.Title}
        />
      )}
      <section
        className={
          classes.sectionContent +
          "   text-left content5 centeradd cid-szWdSGiEXu"
        }
      >
        <Container>
          <Row>
            <Col
              xs="12"
              md="12"
              className={data?.PageImages?.length > 0 ? "" : ""}
            >
              <div className={classes.breadcrumb + " mb-4"}>
                <div>
                  <NavLink to="/">HOME</NavLink>
                  <span className={classes.bread + " me-1 ms-1"}>
                    &gt;
                  </span>{" "}
                  {path?.map((m) => {
                    let pth = data?.Path.substring(
                      0,
                      data?.Path.indexOf(m) + m.length
                    );
                    return (
                      <>
                        <NavLink to={"/" + pth}>
                          {m.replace(/-/g, " ").toUpperCase()}
                        </NavLink>

                        <span className={classes.bread + " me-1 ms-1"}>
                          &gt;
                        </span>
                      </>
                    );
                  })}
                  {data?.Title}
                </div>
              </div>
              <h1 className="mbr-section-title mbr-fonts-style mbr-black mb-3 text-bold display-5">
                {data?.PageTitle}
              </h1>
              {display}
            </Col>
          </Row>
          {(table1 || table2) && (
            <Row>
              <Col md={12}>
                {table1 ? (
                  <Row>
                    <Col xs={12} className="mb-5">
                      {table1 ? (
                        <TableContainer component={Paper}>
                          <Table aria-label="customized table">
                            <TableHead>
                              <TableRow>
                                <TableCell>The area</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {table1.map((data, id) => {
                                return (
                                  <TableRow>
                                    <TableCell>{data.terminal}</TableCell>
                                  </TableRow>
                                );
                              })}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      ) : null}
                    </Col>
                  </Row>
                ) : null}
                {table2 ? (
                  <Row>
                    <Col xs={12} className="mb-5">
                      {table2 ? (
                        <TableContainer component={Paper}>
                          <Table aria-label="customized table">
                            <TableHead>
                              <TableRow>
                                <TableCell>Special Cargo</TableCell>
                                <TableCell>Yes</TableCell>
                                <TableCell>No</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {table2.map((data, id) => {
                                return (
                                  <TableRow>
                                    <TableCell>{data.value}</TableCell>
                                    <TableCell>{data.yes ? "X" : ""}</TableCell>

                                    <TableCell>
                                      {!data.yes ? "X" : ""}
                                    </TableCell>
                                  </TableRow>
                                );
                              })}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      ) : null}
                    </Col>
                  </Row>
                ) : null}
              </Col>
            </Row>
          )}
          {data?.News?.content && (
            <Row>
              <Col>
                {data?.News?.content &&
                  data?.News?.content?.map((info, id) => {
                    return documentToReactComponents(info, options);
                  })}
              </Col>
            </Row>
          )}
        </Container>
      </section>
    </>
  );
};

export default withRouter(BillundFacilities);
