import axios from 'axios';

export const homeApi = async (url) => {
    let response = await axios.get(url+"/getPage/Sgh");
    return response.data;
}

export const previewApi = async (url) => {
    let response = await axios.get(url+"/getPage/preview/Sgh");
    return response.data;
}