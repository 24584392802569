import axios from 'axios';

export const LocationApi= async (url) => {
    let response = await axios.get(url+"/getPage/OurLocations");
    return response.data;
}


export const LocationPreviewApi = async (url) => {
    let response = await axios.get(url+"/getPage/preview/OurLocations");
    return response.data;
}