import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { withRouter } from "react-router-dom";
import classes from "./RegistrationForm.module.css";
import { Row, Col, Container } from "react-bootstrap";
import { postApi } from "../../Services/SghTrainingService";
import TextareaAutosize from "@mui/base/TextareaAutosize";
import TextForm from "../../../src/utils/TextForm/TextForm";
import Button from "@mui/material/Button";
import Warning from "../../../src/utils/Warning/Warning";
import { useSelector } from "react-redux";
import "../../assets/mobirise/css/mbr-additional.css";
import { PageTitle } from "../../utils/common";

const RegistrationForm = (props) => {
  let location = useLocation();
  const textInput = React.useRef(null);
  const url = useSelector((state) => state.url);
  const [courseName, setCourseName] = useState("");
  const [courseLocation, setCourseLocation] = useState("");
  const [price, setPrice] = useState("");
  const [start, setStart] = useState("");
  const [end, setEnd] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [company, setCompany] = useState("");
  const [streetAddress, setStreetAddress] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");
  const [telephone, setTelephone] = useState("");
  const [fax, setFax] = useState("");
  const [billingCompany, setBillingCompany] = useState("");
  const [attention, setAttention] = useState("");
  const [billingStreetAddress, setBillingStreetAddress] = useState("");
  const [billingPostalCode, setBillingPostalCode] = useState("");
  const [billingCity, setBillingCity] = useState("");
  const [billingCountry, setBillingCountry] = useState("");
  const [euVatNo, setEUVatNo] = useState("");
  const [addtionalInformation, setAdditionalInformation] = useState("");
  const [submit, setSubmit] = useState(true);
  const [warning, setWarning] = useState(false);

  const [warningtext, setWarningText] = useState("");

  const handleSubmit = async (e) => {
    if (
      courseName.length == 0 ||
      courseLocation.length == 0 ||
      price.length == 0 ||
      start.length == 0 ||
      end.length == 0 ||
      firstName.length == 0 ||
      lastName.length == 0 ||
      email.length == 0 ||
      company.length == 0 ||
      country.length == 0 ||
      city.length == 0 ||
      streetAddress.length == 0 ||
      postalCode.length == 0 ||
      telephone.length == 0 ||
      billingCity.length == 0 ||
      billingCompany.length == 0 ||
      attention.length == 0 ||
      billingCountry.length == 0 ||
      billingPostalCode.length == 0 ||
      billingStreetAddress.length == 0
    ) {
      setSubmit(false);
      setWarningText("Please fill the mandatory fields");
      setWarning(true);
      return;
    }

    try {
      let response = await postApi(
        url,
        courseName,
        courseLocation,
        price,
        start,
        end,
        firstName,
        lastName,
        email,
        company,
        streetAddress,
        postalCode,
        city,
        country,
        telephone,
        fax,
        billingCompany,
        attention,
        billingStreetAddress,
        billingPostalCode,
        billingCity,
        billingCountry,
        euVatNo,
        addtionalInformation
      );

      if (response.statusCode == 200 && response.success) {
        setWarningText(
          "Registration was a success. You should receive a confirmation mail shortly"
        );

        setWarning(true);
        setFirstName("");
        setLastName("");
        setEmail("");
        setCompany("");
        setStreetAddress("");
        setPostalCode("");
        setAttention("");
        setAdditionalInformation("");
        setBillingStreetAddress("");
        setBillingCity("");
        setBillingCompany("");
        setBillingPostalCode("");
        setBillingCountry("");
        setEUVatNo("");
        setTelephone("");
        setFax("");
        setCity("");
        setCountry("");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleRemoveWarning = () => {
    setWarning(false);
    setWarningText("");
  };

  useEffect(async () => {
    setCourseName(location.state.name);
    setStart(location.state.start);
    setEnd(location.state.end);
    setPrice(location.state.price);
    setCourseLocation(location.state.location);
    PageTitle(location.state.title);
  }, []);

  let displayWarning = null;

  if (warning) {
    displayWarning = <center>{warningtext}</center>;
  }

  return (
    <>
      <div>
        <Warning remove={handleRemoveWarning} show={warning}>
          {displayWarning}
        </Warning>
      </div>
      <section
        className={" text-left content5 centeradd cid-szWdSGiEXu text-center"}
        style={{
          backgroundColor: "#e7e7e7",
        }}
      >
        <Container>
          <Row className="mb-5">
            <Col>
              <div>
                <p className="mbr-fonts-style mbr-text mbr-white mb-0 display-7">
                  <strong>Course name:</strong> {location.state.name}
                </p>
              </div>
            </Col>
            <Col>
              <div>
                <p className="mbr-fonts-style mbr-text mbr-white mb-0 display-7">
                  <strong>Location:</strong> {location.state.location}
                </p>
              </div>
            </Col>
            <Col>
              <div>
                <p className="mbr-fonts-style mbr-text mbr-white mb-0 display-7">
                  <strong>Date:</strong> {location.state.start.substring(0, 10)}{" "}
                  - {location.state.end.substring(0, 10)}
                </p>
              </div>
            </Col>
            <Col>
              <div>
                <p className="mbr-fonts-style mbr-text mbr-white mb-0 display-7">
                  <strong>Price:</strong> {location.state.price}
                </p>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <h2 className="mbr-section-title mbr-fonts-style mbr-black mt-3 mb-0 display-6">
                Course Information and Personal Details
              </h2>
            </Col>
          </Row>

          <Row>
            <Col xs="12">
              <div className={classes.inputform + " mt-0 mt-md-3"}>
                <div
                  className={
                    classes.textformwrapper +
                    " mt-3 mt-md-0 ps-2 pe-2 ps-md-0 pe-md-0"
                  }
                >
                  <TextForm
                    value={(val) => setFirstName(val)}
                    label="First name"
                    unique="firstName"
                    isEmpty={
                      submit === false && firstName.length == 0 ? false : true
                    }
                    required
                    type="text"
                  />
                </div>
                <div
                  className={
                    classes.textformwrapper +
                    " mt-3 mt-md-0 ps-2 pe-2 ps-md-0 pe-md-0"
                  }
                >
                  <TextForm
                    value={(val) => setLastName(val)}
                    label="Last name"
                    isEmpty={
                      submit === false && lastName.length == 0 ? false : true
                    }
                    required
                    type="text"
                  />
                </div>
                <div
                  className={
                    classes.textformwrapper +
                    " mt-3 mt-md-0 ps-2 pe-2 ps-md-0 pe-md-0"
                  }
                >
                  <TextForm
                    value={(val) => setEmail(val)}
                    label="E-mail (Student's e-mail address)"
                    isEmpty={
                      submit == false && email.length == 0 ? false : true
                    }
                    required
                    type="email"
                  />
                </div>
              </div>
              <div className={classes.inputform + " mt-0 mt-md-3"}>
                <div
                  className={
                    classes.textformwrapper +
                    " mt-3 mt-md-0 ps-2 pe-2 ps-md-0 pe-md-0"
                  }
                >
                  <TextForm
                    value={(val) => setCompany(val)}
                    label="Company"
                    isEmpty={
                      submit === false && company.length == 0 ? false : true
                    }
                    required
                    type="text"
                  />
                </div>
                <div
                  className={
                    classes.textformwrapper +
                    " mt-3 mt-md-0 ps-2 pe-2 ps-md-0 pe-md-0"
                  }
                >
                  <TextForm
                    value={(val) => setStreetAddress(val)}
                    label="Street address/P.O. Box"
                    isEmpty={
                      submit == false && streetAddress.length == 0
                        ? false
                        : true
                    }
                    required
                    type="text"
                  />
                </div>

                <div
                  className={
                    classes.textformwrapper +
                    " mt-3 mt-md-0 ps-2 pe-2 ps-md-0 pe-md-0"
                  }
                >
                  <TextForm
                    value={(val) => setPostalCode(val)}
                    label="Postal code"
                    isEmpty={
                      submit == false && postalCode.length == 0 ? false : true
                    }
                    required
                    type="number"
                    maxlength="6"
                  />
                </div>
              </div>
              <div className={classes.inputform + " mt-0 mt-md-3"}>
                <div
                  className={
                    classes.textformwrapper +
                    " mt-3 mt-md-0 ps-2 pe-2 ps-md-0 pe-md-0"
                  }
                >
                  <TextForm
                    value={(val) => setCity(val)}
                    label="City"
                    isEmpty={submit == false && city.length == 0 ? false : true}
                    required
                    type="text"
                  />
                </div>

                <div
                  className={
                    classes.textformwrapper +
                    " mt-3 mt-md-0 ps-2 pe-2 ps-md-0 pe-md-0"
                  }
                >
                  <TextForm
                    value={(val) => setCountry(val)}
                    label="Country"
                    isEmpty={
                      submit == false && country.length == 0 ? false : true
                    }
                    required
                    type="text"
                  />
                </div>

                <div
                  className={
                    classes.textformwrapper +
                    " mt-3 mt-md-0 ps-2 pe-2 ps-md-0 pe-md-0"
                  }
                >
                  <TextForm
                    value={(val) => setTelephone(val)}
                    label="TelePhone"
                    isEmpty={
                      submit == false && telephone.length == 0 ? false : true
                    }
                    required
                    type="number"
                    maxlength="10"
                  />
                </div>

                <div
                  className={
                    classes.textformwrapper +
                    " mt-3 mt-md-0 ps-2 pe-2 ps-md-0 pe-md-0"
                  }
                >
                  <TextForm
                    value={(val) => setFax(val)}
                    label="Fax"
                    isEmpty={true}
                    type="number"
                  />
                </div>
              </div>
            </Col>

            <Col xs="12" className="mt-3">
              <div>
                <h2 className="mbr-section-title mbr-fonts-style mbr-black mt-3 mb-3 display-6">
                  Billing Address
                  <br></br> Only if the billing address differs from the address
                  above.
                </h2>
              </div>
              <div className={classes.inputform + " mt-0 mt-md-3"}>
                <div
                  className={
                    classes.textformwrapper +
                    " mt-3 mt-md-0 ps-2 pe-2 ps-md-0 pe-md-0"
                  }
                >
                  <TextForm
                    value={(val) => setBillingCompany(val)}
                    label="Company"
                    isEmpty={
                      submit === false && billingCompany.length == 0
                        ? false
                        : true
                    }
                    required
                    type="text"
                  />
                </div>

                <div
                  className={
                    classes.textformwrapper +
                    " mt-3 mt-md-0 ps-2 pe-2 ps-md-0 pe-md-0"
                  }
                >
                  <TextForm
                    value={(val) => setAttention(val)}
                    label="Attention"
                    isEmpty={
                      submit == false && attention.length == 0 ? false : true
                    }
                    required
                    type="text"
                  />
                </div>

                <div
                  className={
                    classes.textformwrapper +
                    " mt-3 mt-md-0 ps-2 pe-2 ps-md-0 pe-md-0"
                  }
                >
                  <TextForm
                    value={(val) => setBillingStreetAddress(val)}
                    label="Street address/P.O. Box"
                    isEmpty={
                      submit == false && billingStreetAddress.length == 0
                        ? false
                        : true
                    }
                    required
                    type="text"
                  />
                </div>
              </div>
              <div className={classes.inputform + " mt-0 mt-md-3"}>
                <div
                  className={
                    classes.textformwrapper +
                    " mt-3 mt-md-0 ps-2 pe-2 ps-md-0 pe-md-0"
                  }
                >
                  <TextForm
                    value={(val) => setBillingPostalCode(val)}
                    label="Postal/Zip code"
                    isEmpty={
                      submit == false && billingPostalCode.length == 0
                        ? false
                        : true
                    }
                    required
                    type="number"
                    maxlength="6"
                  />
                </div>

                <div
                  className={
                    classes.textformwrapper +
                    " mt-3 mt-md-0 ps-2 pe-2 ps-md-0 pe-md-0"
                  }
                >
                  <TextForm
                    value={(val) => setBillingCity(val)}
                    label="City"
                    isEmpty={
                      submit == false && billingCity.length == 0 ? false : true
                    }
                    required
                    type="text"
                  />
                </div>

                <div
                  className={
                    classes.textformwrapper +
                    " mt-3 mt-md-0 ps-2 pe-2 ps-md-0 pe-md-0"
                  }
                >
                  <TextForm
                    value={(val) => setBillingCountry(val)}
                    label="Country"
                    isEmpty={
                      submit == false && billingCountry.length == 0
                        ? false
                        : true
                    }
                    required
                    type="text"
                  />
                </div>
              </div>
              <div className={classes.inputform + " mt-0 mt-md-3"}>
                <div
                  className={
                    classes.textformwrapper +
                    " mt-3 mt-md-0 ps-2 pe-2 ps-md-0 pe-md-0"
                  }
                >
                  <TextForm
                    value={(val) => setEUVatNo(val)}
                    label="EU VAT No."
                    isEmpty={true}
                  />
                </div>
              </div>
              <div className={classes.inputform + " mt-0 mt-md-3"}>
                <div
                  className={
                    classes.textformwrapper +
                    " mt-3 mt-md-0 ps-2 pe-2 ps-md-0 pe-md-0"
                  }
                >
                  <TextareaAutosize
                    value={(val) => setAdditionalInformation(val)}
                    minRows={6}
                    aria-label="maximum height"
                    className="form-control"
                    style={{ width: "100%" }}
                    placeholder="Additional Informations"
                  />
                </div>
              </div>

              <div className="text-center mt-3">
                <Button
                  variant="contained"
                  onClick={handleSubmit}
                  className={classes.button}
                >
                  SUBMIT
                </Button>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default withRouter(RegistrationForm);
