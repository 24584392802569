import axios from 'axios';

export const ContactApi= async (url) => {
    let response = await axios.get(url+"/getPage/Contact");
    return response.data;
}

export const ContactPreviewApi = async (url) => {
    let response = await axios.get(url+"/getPage/preview/Contact");
    return response.data;
}