import React from 'react';
import classes from './Warning.module.css';
import ClearIcon from '@mui/icons-material/Clear';

const Warning = (props) =>{
    // console.log("Render Warning");
    // console.log("props.show : ",props.show);
    return(
        <div style={{width: "100%"}}>
            {props.show?(
            <div className={classes.Warning}>
                <div className={classes.warningwrapper+" pt-2 pb-2"}>
                    <div className={classes.warningtext}>
                        {props.children}
                    </div>
                    <div>
                        <ClearIcon onClick={props.remove} className={classes.ClearIcon} />
                    </div>
                </div>
                
            </div>):null}
        </div>
    )
}

export default Warning;