import { useState, useEffect, useRef } from "react";
import "./NavbarItem.css";
import { Link, NavLink, useLocation } from "react-router-dom";
import SubSubMenu from "./SubSubMenu";
import { listItemTextClasses } from "@mui/material";

const SubMenu = ({ items, depthLevel }) => {
  const [dropdown, setDropdown] = useState(false);

  let ref = useRef();
  useEffect(() => {
    const handler = (event) => {
      if (dropdown && ref.current && !ref.current.contains(event.target)) {
        setDropdown(false);
      }
    };
    document.addEventListener("mousedown", handler);
    document.addEventListener("touchstart", handler);
    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", handler);
      document.removeEventListener("touchstart", handler);
    };
  }, [dropdown]);
  const onMouseEnter = () => {
    window.innerWidth > 960 && setDropdown(true);
  };

  const onMouseLeave = () => {
    window.innerWidth > 960 && setDropdown(false);
  };
  return (
    <li
      className="menu-items"
      ref={ref}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {items?.submenu ? (
        <>
          <Link
            to={
              items.slug != "/"
                ? items.mainmenu != ""
                  ? "/" + items.mainmenu + "/" + items.slug
                  : "/" + items.slug
                : "/"
            }
            aria-haspopup="menu"
            aria-expanded={dropdown ? "true" : "false"}
            onClick={() => setDropdown((prev) => !prev)}
          >
            {items?.title}
            {items.submenu.length > 0 ? <span className="arrow" /> : ""}
          </Link>
          <SubSubMenu
            depthLevel={depthLevel}
            submenus={items?.submenu}
            dropdown={dropdown}
            length={items.submenu.length}
          />
        </>
      ) : (
        <a href="/#"> {items?.title} </a>
      )}{" "}
    </li>
  );
};
export default SubMenu;
