import React, { useEffect, useState } from "react";
import classes from "./Contact.module.css";
import Top from "../../utils/Top/Top";
import { ContactApi, ContactPreviewApi } from "../../Services/ContactService";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { useDispatch } from "react-redux";
import { Row, Container, Col } from "react-bootstrap";
import { NavLink, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { BLOCKS, MARKS, INLINES } from "@contentful/rich-text-types";
import "../../assets/mobirise/css/mbr-additional.css";
import "../../assets/theme/css/style.css";
import Slider from "../../Components/Slider/Slider";
import { PageTitle } from "../../utils/common";

const Contact = () => {
  const Dispatch = useDispatch();
  let location = useLocation();
  const url = useSelector((state) => state.url);
  const [contact, setContact] = useState(null);
  useEffect(async () => {
    let contactData;
    const queryParams = new URLSearchParams(location.search);
    const val = queryParams.get("v") || "";
    if (val == "preview") {
      contactData = await ContactPreviewApi(url);
    } else {
      contactData = await ContactApi(url);
    }
    PageTitle(contactData?.Title);
    setContact(contactData);
  }, []);
  const HEADING_1 = ({ children }) => (
    <h1 className="mbr-section-title mbr-fonts-style mbr-black mb-3  display-5">
      {children}
    </h1>
  );
  const HEADING_2 = ({ node, children }) => (
    <h2 className="mbr-section-title mbr-fonts-style mbr-black mb-3 display-5">
      {/* {node.content.value} */}
      {children}
    </h2>
  );
  const HEADING_3 = ({ children }) => (
    <h3 className="mbr-fonts-style mbr-black mb-3 text-center display-6">
      {children}
    </h3>
  );

  const options = {
    renderNode: {
      [BLOCKS.HEADING_1]: (node, children) => <HEADING_1>{children}</HEADING_1>,
      [BLOCKS.HEADING_2]: (node, children) => <HEADING_2>{children}</HEADING_2>,
      [BLOCKS.HEADING_3]: (node, children) => <HEADING_3>{children}</HEADING_3>,
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <p className={paragraphClass(node)}>{children}</p>
      ),
    },
  };
  function paragraphClass(node) {
    const className = "mbr-fonts-style mbr-text mbr-white mb-3 display-7";
    return className;
  }
  let display = null,
    path = [];

  if (contact) {
    display = contact?.Description?.content?.map((info, id) => {
      return documentToReactComponents(info, options);
    });
    path = contact?.Path.split("/");
    path = path.slice(0, path.length - 1);
  }

  return (
    <>
      {contact?.PageImages?.length > 0 && (
        <Top
          pageTitle={contact?.PageTitle}
          images={contact?.PageImages}
          title={contact?.Title}
        />
      )}

      <section className={" text-left content5 centeradd cid-szWdSGiEXu"}>
        <Container>
          <Row>
            <Col
              xs="12"
              md="12"
              className={contact?.PageImages?.length > 0 ? "" : ""}
            >
              <div className={classes.breadcrumb + " mb-4"}>
                <div>
                  <NavLink to="/">HOME</NavLink>
                  <span className={classes.bread + " me-1 ms-1"}>
                    &gt;
                  </span>{" "}
                  {path?.map((m) => {
                    let pth = contact?.Path.substring(
                      0,
                      contact?.Path.indexOf(m) + m.length
                    );
                    return (
                      <>
                        <NavLink to={"/" + pth}>
                          {m.replace(/-/g, " ").toUpperCase()}
                        </NavLink>

                        <span className={classes.bread + " me-1 ms-1"}>
                          &gt;
                        </span>
                      </>
                    );
                  })}
                  {contact?.Title}
                </div>
              </div>
              <h1 className="mbr-section-title mbr-fonts-style mbr-black mb-3 text-bold display-5">
                {contact?.PageTitle}
              </h1>
              {display}
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Contact;
