export const menuItems = [
  {
    title: "Home",
    slug: "/",
    id: "372R22W0nmXiUdqrWOYBf1",
    mainmenu: "",
    submenu: [
      {
        title: "Locations",
        slug: "locations",
        mainmenu: "",
        id: "2pIom106GJVh9P05UujXSL",
        submenu: [
          {
            mainmenu: "locations",
            title: "Denmark",
            slug: "Denmark",
            id: "5j8wOfa8EszHk6tBXDtSKW",

            submenu: [
              {
                title: "Copenhagen",
                slug: "Copenhagen",
                mainmenu: "locations/Denmark",
                id: "3ivwGSAOhDBkTibuZ9XKcH",

                submenu: [
                  {
                    title: "Facilities",
                    slug: "Facilities",
                    mainmenu: "locations/Denmark/Copenhagen",
                    id: "58eLQz1BZ59r9y2pqV9Bfy",
                    submenu: [],
                  },
                  {
                    title: "Opening Hours",
                    slug: "OpeningHours",
                    mainmenu: "locations/Denmark/Copenhagen",
                    id: "3EgCqTwx81vgntmj2pqypZ",

                    submenu: [],
                  },
                  {
                    title: "Our Customers",
                    slug: "OurCustomers",
                    mainmenu: "locations/Denmark/Copenhagen",
                    id: "irN5e3U4lpwaaWZSj5fJJ",

                    submenu: [],
                  },
                ],
              },
              {
                title: "Billund",
                slug: "Billund",
                mainmenu: "locations/Denmark",
                id: "x7dBHLv6f2A5xecp3XBND",

                submenu: [
                  {
                    title: "Facilities",
                    slug: "Facilities",
                    id:"5niiiXfmOzCDtPaaxwz5va",
                    mainmenu: "locations/Denmark/Billund",
                    submenu: [],
                  },
                  {
                    title: "Opening Hours",
                    slug: "OpeningHours",
                    actual_slug:"billund-opening-hours",
                    mainmenu: "locations/Denmark/Billund",
                    id: "SsOUi7Jvq1SgT663ccC82",

                    submenu: [],
                  },
                  {
                    title: "Our Customers",
                    slug: "OurCustomers",
                    actual_slug:"billund-our-customers",
                    mainmenu: "locations/Denmark/Billund",
                    id: "6fmE05HI4i67Zb2SUELCIO",

                    submenu: [],
                  },
                ],
              },
            ],
          },
          {
            mainmenu: "locations",
            title: "Norway",
            slug: "Norway",
            id: "2Vc3sULtlFHpZaCrKG1DP6",

            submenu: [
              {
                title: "Oslo",
                slug: "Oslo",
                mainmenu: "locations/Norway",
                id: "3Hecckym3sXYVg3M1dNx6o",
                submenu: [
                  {
                    title: "Facilities",
                    slug: "Facilities",
                    id:"5QfjCs2rvkxAMYPPmBhREE",
                    mainmenu: "locations/Norway/Oslo",
                    submenu: [],
                  },
                  {
                    title: "Opening Hours",
                    slug: "OpeningHours",
                    mainmenu: "locations/Norway/Oslo",
                    id: "SsOUi7Jvq1SgT663ccC82",

                    submenu: [],
                  },
                  {
                    title: "Our Customers",
                    slug: "OurCustomers",
                    mainmenu: "locations/Norway/Oslo",
                    id: "6fmE05HI4i67Zb2SUELCIO",

                    submenu: [],
                  },
                ]
              },
            ],
          },
          {
            mainmenu: "locations",
            title: "Sweden",
            slug: "Sweden",
            id: "2MGX3cosDeReLYngGczYmy",

            submenu: [
              {
                title: "Arlanda",
                slug: "Arlanda",
                mainmenu: "locations/Sweden",
                id: "2hazO0LQKfQFwc5ttcLUg8",

                submenu: [
                  {
                    title: "Facilities",
                    slug: "Facilities",
                    id:"4rXCY32zIEz8IBjq55l7OS",
                    mainmenu: "locations/Sweden/Arlanda",
                    submenu: [],
                  },
                  {
                    title: "Opening Hours",
                    slug: "OpeningHours",
                    mainmenu: "locations/Sweden/Arlanda",
                    id: "5qCDnzIMvdEt844dRMZzaj",

                    submenu: [],
                  },
                  {
                    title: "Our Customers",
                    slug: "OurCustomers",
                    mainmenu: "locations/Sweden/Arlanda",
                    id: "6VluTDjZoej95qVDC56BRu",

                    submenu: [],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    title: "Services",
    slug: "services",
    id: "53BJdjVE5gdPTrmFXswPHG",
    mainmenu: "",
    submenu: [
      {
        title: "Cargo Ramp Handling",
        slug: "cargo-ramp-handling",
        mainmenu: "services",
        id: "2Zgp5moboPdcDRdBMlKxoN",

        submenu: [],
      },
      {
        title: "WareHouse Handling",
        slug: "warehouse-handling",
        mainmenu: "services",
        id: "4tYATMVH9oNJG3Qi4VDT7G",

        submenu: [],
      },
      {
        title: "Other WareHouse Services",
        slug: "other-warehouse-services",
        mainmenu: "services",
        id: "61RRpiKCjvHWb4v7v8iOat",

        submenu: [],
      },
    ],
  },
  {
    title: "About Us",
    slug: "about-us",
    id: "7mLdfIPLqtHMBR9TEAW0W9",
    mainmenu: "",
    submenu: [
      {
        title: "Quality",
        slug: "quality",
        id: "38fU4CapYRvFswpt4RXPwo",
        mainmenu: "about-us",

        submenu: [],
      },
      {
        title: "Environment",
        slug: "environment",
        id: "4lGylC0tPZjg662pObtuJL",
        mainmenu: "about-us",

        submenu: [],
      },

      {
        title: "Safety & Security",
        slug: "safety-and-security",
        id: "6gN0Um8qBnaL6iY4SEKDIl",
        mainmenu: "about-us",

        submenu: [],
      },
      {
        title: "Certificates",
        slug: "certificates",
        id: "2zd1pO6apQPrt72zlbVmZW",
        mainmenu: "about-us",

        submenu: [],
      },

      {
        title: "Sustainability",
        slug: "sustainability",
        id: "37yrRvqzb3HF7Vz593awyN",
        mainmenu: "about-us",

        submenu: [],
      },
      {
        title: "Pharma",
        slug: "pharma",
        id: "DIxzbK2nLmBxl7qKGTZ1F",
        mainmenu: "about-us",

        submenu: [],
      },
    ],
  },
  {
    title: "Contact",
    slug: "contact-us",
    id: "6fw3k5cV8HDPiGSgPOXiKn",
    mainmenu: "",
    submenu: [
      {
        title: "Denmark",
        slug: "denmark",
        id: "45sEHtEs2TUtJ2orthc5bd",
        mainmenu: "contact-us",

        submenu: [],
      },
      {
        title: "Norway",
        slug: "norway",
        id: "5xwefnJxhuzJpnKSc7zvHR",
        mainmenu: "contact-us",

        submenu: [],
      },
      {
        title: "Sweden",
        slug: "sweden",
        id: "5QpI1AbcNFkMGXNXr1BFdC",
        mainmenu: "contact-us",

        submenu: [],
      },
    ],
  },
  {
    title: "Search",
    slug: "search",
    id: "",
    mainmenu: "",
    submenu: [],
  },
];
