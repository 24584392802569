import React, { useEffect, useState } from "react";
import { headerApi, headerPreviewApi ,quickTrackApi} from "../../Services/HeaderService";
import { withRouter } from "react-router-dom";
import { AwbApi } from "../../Services/AwbService";
import { useSelector } from "react-redux";
import { Link, useLocation,NavLink } from "react-router-dom";
import Button from "@mui/material/Button";
import "../../assets/theme/css/main.css";
import {
  AppBar,
  Toolbar,
  CssBaseline,
  makeStyles,
  useTheme,
  // useStyles,
  useMediaQuery,
} from "@material-ui/core";
import DrawerComponent from "../NavbarItem/Drawer";
import "../../assets/mobirise/css/mbr-additional.css";
import SubMenu from "../NavbarItem/SubMenu";
import "../NavbarItem/menu.css";
import { menuItems } from "../NavbarItem/menuItems";
import { Row, Col, Container } from "react-bootstrap";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { BLOCKS, INLINES } from "@contentful/rich-text-types";
import Modal from "react-bootstrap/Modal";
import TextForm from "../../../src/utils/TextForm/TextForm";

const Header = (props) => {
  let location = useLocation();
  const url = useSelector((state) => state.url);
  const [header, setHeader] = useState(null);
  const [quickTrack, setQuickTrack] = useState(null);
  const [number, setNumber] = useState("");
  const [show, setShow] = useState(false);
  const [submit, setSubmit] = useState(true);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [warning, setWarning] = useState(false);
  const [warningtext, setWarningText] = useState("");
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const useStyles = makeStyles((theme) => ({
    navlinks: {
      marginBottom: theme.spacing(0),
      marginLeft: theme.spacing(0),
      paddingLeft: theme.spacing(0),
      display: "flex",
      listStyle: "none",
    },
    logo: {
      width: "150px",
      cursor: "pointer",
    },
    link: {
      textDecoration: "none",
      color: "white",
      fontSize: "20px",
      marginLeft: theme.spacing(20),
      "&:hover": {
        color: "yellow",
        borderBottom: "1px solid white",
      },
    },
  }));
  const classes = useStyles();

  const handleRemoveWarning = () => {
    setWarning(false);
    setWarningText("");
  };

  const handleFetch = async () => {
    if (number.length == 0) {
      setSubmit(false);
      setWarningText("Please fill the mandatory fields");
      setWarning(true);
      return;
    }
    if (number.length == 12 || number.length == 11) {
      const p2 = new RegExp(/^[0-9\-]+$/);
      if (!p2.test(number)) {
        setSubmit(false);
        setWarningText("Please enter a valid Airway Bill Number");
        setWarning(true);
        return;
      }
    } else {
      setSubmit(false);
      setWarningText("Please enter a valid Airway Bill Number");
      setWarning(true);
      return;
    }

    try {

      let response = await AwbApi(
        url,
        number.length==12? number.substring(0, 3) + number.substring(4):number
      );
      let res = response.data;
      if (res.statusCode == 200 && res.success) {
        setShow(false);
        let json = JSON.parse(res.data);
        props.history.push({
          pathname: "/track-data",
          state: { data: json },
        });
      }
    } catch (error) {}
  };

  const HEADING_1 = ({ children }) => (
    <h1 className="mbr-section-title mbr-fonts-style mbr-black mb-3 display-5">
      {children}
    </h1>
  );
  const HEADING_2 = ({ node, children }) => (
    <h2 className="mbr-fonts-style mbr-black mb-3 display-6">{children}</h2>
  );
  const HEADING_3 = ({ children }) => (
    <h3 className="mbr-fonts-style mbr-black mb-3 text-center display-7">
      {children}
    </h3>
  );

  const options = {
    renderNode: {
      [BLOCKS.HEADING_1]: (node, children) => <HEADING_1>{children}</HEADING_1>,
      [BLOCKS.HEADING_2]: (node, children) => <HEADING_2>{children}</HEADING_2>,
      [BLOCKS.HEADING_3]: (node, children) => <HEADING_3>{children}</HEADING_3>,
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <p className={paragraphClass(node)}>{children}</p>
      ),
      [INLINES.ENTRY_HYPERLINK]: (node, children) => {
        if (
          node.data.target.sys.id == "6FvO1TyAQwfouFtAjTED3G" ||
          node.data.target.sys.id == "1vqomCCKM8LiY3cYnyfINQ"
        ) {
          return (
            <NavLink
              to={{
                pathname: "/contact-us/denmark",
                aboutProps: {
                  id: node.data.target.sys.id,
                },
              }}
              style={{ textDecoration: "none" }}
            >
              {children}
            </NavLink>
          );
        } 
      },

      [INLINES.ASSET_HYPERLINK]: (node, children) => {
        return (
          <a
            href={`${node?.data?.target?.fields?.file?.url}`}
            target="_blank"
            download
            style={{ textDecoration: "none" }}
          >
            {children}
          </a>
        );
      },
      [INLINES.HYPERLINK]: (node, children) => {
        return (
          <a
            href={`${node?.data?.uri}`}
            target="_blank"
            download
            style={{ textDecoration: "none" }}
          >
            {children}
          </a>
        );
      },
    },
  };
  function paragraphClass(node) {
    const className = "mbr-fonts-style mbr-text mbr-white mb-3  text-center display-7";
    return className;
  }
  useEffect(async () => {
    let headerData,quickTrackData;
    const queryParams = new URLSearchParams(location.search);
    const val = queryParams.get("v") || "";
    if (val == "preview") {
      headerData = await headerPreviewApi(url);
      quickTrackData = await quickTrackApi(url);
    } else {
      headerData = await headerApi(url);
      quickTrackData = await quickTrackApi(url);
    }
    setHeader(headerData);
    setQuickTrack(quickTrackData);


  }, []);

  let displayHeader = null;
  let displayWarning,quickTrackDesc,quickTrackTitle = null;

  if (warning) {
    displayWarning = <center>{warningtext}</center>;
  }

  const handleHome = () => {
    props.history.push({
      pathname: "/",
    });
  };

  if (quickTrack) {
    quickTrackDesc = quickTrack?.Description?.content?.map((info, id) => {
      return documentToReactComponents(info, options);
    });
    quickTrackTitle = quickTrack?.Title;

  }

  return (
    <>
      <AppBar position="static">
        <CssBaseline />
        <Toolbar>
          <Link to="/" className={classes.logo}>
            <img src={"https:" + header?.Logo} className={classes.logo}></img>
          </Link>
          {isMobile ? (
            <DrawerComponent Menus={header?.Menu} />
          ) : (
            <ul className={classes.navlinks + " mr-3"}>
              {menuItems.map((menu, index) => {
                const depthLevel = 0;
                // console.log(menu);
                return (
                  <SubMenu items={menu} key={index} depthLevel={depthLevel} />
                );
              })}
              <li className="menu-items" onClick={handleShow}>
                <a class="makeStyles-link-13" href="#">
                  Quick Track
                </a>
              </li>
            </ul>
            //   {header?.Menu?.map((info, id) => {
            //     return <NavbarItem info={info} />;
            //   })}
          )}
        </Toolbar>
      </AppBar>
      <Modal show={show} onHide={handleClose}>
        <Modal.Body className="p-0">
          <section className=" text-center cid-szWdJmjpxZ section-bg">
            <Container>
              
              <Row>
                <Col md={12}>
                  <h1 className="mbr-fonts-style mbr-white mb-3 display-5 ">
                    {/* Quick Tracking */}
                    {quickTrackTitle}
                  </h1>
                  {/* <p className="mbr-fonts-style mbr-text mbr-white text-center mb-5 display-7">
                    <Link to="/Tracking" className="text-decoration-none">
                      Quick tracking only shows spirit system info
                    </Link>
                    &nbsp;and to&nbsp;
                    <a href="https://www.track-trace.com/aircargo" className="">
                      &nbsp;Air cargo tracking - track-trace
                    </a>
                  </p> */}

                  {quickTrackDesc}
                </Col>
              </Row>
              <Row className="">
                <Col md={9} sm={12} className={isMobile ? "w-100 mb-3 " : ""}>
                  <TextForm
                    value={(val) => setNumber(val)}
                    label="Air WayBill Number"
                    isEmpty={
                      submit === false && number.length == 0 ? false : true
                    }
                    className={isMobile ? "w-100" : " "}
                    required
                    minlength="11"
                    maxlength="12"
                    type="text"
                  />
                  <div className="mt-1 text-white">{displayWarning}</div>
                </Col>
                <Col md={3} sm={12} className={isMobile ? "w-100 mb-3 " : ""}>
                  <Button
                    type="button"
                    onClick={handleFetch}
                    className={
                      isMobile
                        ? "btn btn-black display-4"
                        : "btn btn-black btn-height display-4 justify-content-start"
                    }
                  >
                    Track
                  </Button>
                </Col>
              </Row>
            </Container>
          </section>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default withRouter(Header);
