import React, { useEffect, useState } from "react";
import classes from "../Home/Home.module.css";
import { useSelector } from "react-redux";
import { Row, Container, Col } from "react-bootstrap";
import "../../assets/mobirise/css/mbr-additional.css";
import Table from "@mui/material/Table";
import { useLocation } from "react-router-dom";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import { Link } from "react-router-dom";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import "../../assets/theme/css/style.css";
import "../../assets/theme/css/main.css";
import { NavLink } from "react-router-dom";

const Tracking = () => {
  let location = useLocation();
  const pageData = location.state ? location.state.data : location.state;
  console.log(pageData);
  const url = useSelector((state) => state.url);
  const all_status = [];
  const summary = [];
  const temp_arr = [];
  const garvage = [];
  let temp_len = [];
  let status = null;
  let piece = null;
  let s_val,
    temp = "";
  const val = [
    "PICKUP",
    "CHECKED",
    "ASSIGNED",
    "ACCEPTED",
    "MISSING",
    "ARRIVED",
    "AVAILABLE",
    "DEPARTED",
    "DELIVERED",
  ];
  let data,
    error = null;
  if (pageData) {
    console.log("pageData", pageData);
    data = pageData["RESPONSE"]["ECTSTA-OUT"][0];

    error = pageData["RESPONSE"]["ECTSTA-OUT"][0].hasOwnProperty("SVCERROR");
    console.log(error);
  }

  if (error != null && !error) {
    data["HISTORY"][0]["ENTRY"]?.map((info, key) => {
      if (info.hasOwnProperty("AGENT")) {
        for (let a of val) {
          if (info["STATUS"][0].toUpperCase().includes(a)) {
            status =
              a == "PICKUP" ? "available" : a == "CHECKED" ? "arrived" : a;
            break;
          }
        }
        if (
          info["STATUS"][0].toUpperCase().includes("FROM") ||
          info["STATUS"][0].toUpperCase().includes("ASSIGNED")
        ) {
          s_val = info["STATUS"][0].substring(
            info["STATUS"][0].indexOf("--") + 3
          );
          if (info["STATUS"][0].toUpperCase().includes("PIECE")) {
            temp = info["STATUS"][0].substring(
              0,
              info["STATUS"][0].indexOf("PIECES")
            );
            piece = temp.includes("--")
              ? temp.substring(temp.indexOf("--") + 3)
              : temp.substring(0);
          } else {
            piece = "0";
          }
        } else {
          if (info["STATUS"][0].toUpperCase().includes("PIECE")) {
            temp = info["STATUS"][0].substring(
              0,
              info["STATUS"][0].indexOf("PIECES")
            );
            piece = temp.includes("--")
              ? temp.substring(temp.indexOf("--") + 3)
              : temp.substring(0);
          } else {
            piece = "0";
          }

          s_val = `${piece} pieces ${status?.toLowerCase()} at ${
            info["STATION"][0]
          }, ${info["LOCAL"][0]["DATE"][0].substring(0, 2)} ${info["LOCAL"][0][
            "DATE"
          ][0].substring(2)} ${info["LOCAL"][0]["TIME"][0].substring(
            0,
            2
          )}:${info["LOCAL"][0]["TIME"][0].substring(2)}`;
        }
        all_status.push({
          status: s_val,
          key: info["STATION"][0],
          val: status?.toUpperCase(),
          date: info["LOCAL"][0]["DATE"][0],
          time: info["LOCAL"][0]["TIME"][0],
          piece: piece,
          original: info["STATUS"][0],
        });
      }
    });
  }
  if (all_status.length > 0) {
    for (let i = all_status.length - 1; i >= 0; i--) {
      if (all_status[i].piece != "0") temp_arr.push(all_status[i]);
    }
  }

  if (temp_arr.length > 0) {
    for (let i = 0; i < temp_arr.length; i++) {
      temp_len = [];
      if (!garvage.includes(i)) {
        let tem = "";

        if (
          temp_arr[i].original.includes("DEPARTED FROM") ||
          temp_arr[i].original.includes("PICKUP")
        ) {
          tem = temp_arr[i].original.substring(
            temp_arr[i].original.indexOf("PIECES") + 6
          );
        } else {
          tem = temp_arr[i].original.substring(
            temp_arr[i].original.indexOf("PIECES") + 6,
            temp_arr[i].original.indexOf(temp_arr[i].key) + 3
          );
        }

        for (let j = 0; j < temp_arr.length; j++) {
          let tem1 = "";
          if (!garvage.includes(j) && i != j) {
            if (
              temp_arr[j].original.includes("DEPARTED FROM") ||
              temp_arr[j].original.includes("PICKUP")
            ) {
              tem1 = temp_arr[j].original.substring(
                temp_arr[j].original.indexOf("PIECES") + 6
              );
            } else {
              tem1 = temp_arr[j].original.substring(
                temp_arr[j].original.indexOf("PIECES") + 6,
                temp_arr[j].original.indexOf(temp_arr[j].key) + 3
              );
            }
            if (tem1 === tem) {
              temp_len.push({ i: i, j: j });
            }
          }
        }
        var pcs = 0;
        if (temp_len.length > 0) {
          for (var match of temp_len) {
            pcs = parseInt(temp_arr[match.j].piece) + pcs;
            if (!garvage.includes(match.j)) garvage.push(match.j);
          }
          pcs = parseInt(temp_arr[match.i].piece) + pcs;
          summary.push(
            pcs +
              " " +
              temp_arr[match.j].status.substring(
                temp_arr[match.j].status.toUpperCase().indexOf("PIECES")
              )
          );
        } else {
          summary.push(temp_arr[i].status);
          if (!garvage.includes(i)) garvage.push(i);
        }
      }
    }
  }

  return (
    <>
      <section className={" text-left content5 centeradd cid-szWdSGiEXu"}>
        <Container>
          <Row>
            <Col xs="12" md="12">
              <div className={classes.breadcrumb + " mb-4"}>
                <div>
                  <NavLink to="/">HOME</NavLink>
                  <span className={classes.bread + " me-1 ms-1"}>
                    &gt;
                  </span>{" "}
                  Tracking
                </div>
              </div>
              <p className="mbr-fonts-style mbr-text mbr-white text-center display-7">
                <Link to="/" style={{ textDecoration: "none" }}>
                  New Tracking
                </Link>
              </p>
            </Col>

            {error ? (
              <>
                <Col xs={12}>
                  <TableContainer component={Paper}>
                    <Table aria-label="simple table">
                      <TableHead classes={classes.bgTable}>
                        <TableRow>
                          <TableCell>Air waybill number</TableCell>
                          <TableCell align="right">Origin</TableCell>
                          <TableCell align="right">Destination</TableCell>
                          <TableCell align="right">Product</TableCell>
                          <TableCell align="right">Pieces</TableCell>
                          <TableCell align="right">Weight (Kg) </TableCell>
                        </TableRow>
                      </TableHead>
                    </Table>
                  </TableContainer>
                </Col>
                <Col xs={12}>
                  <p className="mbr-fonts-style mbr-text mbr-white text-center display-7">
                    AWB DOES NOT EXIST
                  </p>
                </Col>
              </>
            ) : (
              <>
                {" "}
                <Col xs={12} className="mb-5">
                  <TableContainer component={Paper}>
                    <Table aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell>Air waybill number</TableCell>
                          <TableCell align="right">Origin</TableCell>
                          <TableCell align="right">Destination</TableCell>
                          <TableCell align="right">Product</TableCell>
                          <TableCell align="right">Pieces</TableCell>
                          <TableCell align="right">Weight (Kg) </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          <TableCell>
                            {data && data["DETAIL"][0]["AWB-INFO"][0]["AWB"][0]}
                          </TableCell>
                          <TableCell align="right">
                            {data &&
                              data["DETAIL"][0]["AWB-INFO"][0]["ROUTING"][0][
                                "ORIGIN"
                              ][0]["STATION"]}
                          </TableCell>
                          <TableCell align="right">
                            {data &&
                              data["DETAIL"][0]["AWB-INFO"][0]["ROUTING"][0][
                                "DEST"
                              ][0]["STATION"]}
                          </TableCell>
                          <TableCell align="right">
                            {data &&
                              data["DETAIL"][0]["AWB-INFO"][0]["PROD-DESC"][0]}
                          </TableCell>
                          <TableCell align="right">
                            {data &&
                              data["DETAIL"][0]["AWB-INFO"][0]["TWTOTALS"][0][
                                "PCS"
                              ][0]}
                          </TableCell>
                          <TableCell align="right">
                            {data &&
                              data["DETAIL"][0]["AWB-INFO"][0]["TWTOTALS"][0][
                                "WGT"
                              ][0]}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Col>
                <Col xs={12} className="mb-5">
                  <TableContainer component={Paper}>
                    <Table aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell>Status / History</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {summary?.map((status) => {
                          return (
                            <TableRow
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell component="th" scope="row">
                                {status}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Col>
                <Col xs={12} className="mb-5">
                  <TableContainer component={Paper}>
                    <Table aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell>Track & Trace shipment history</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody></TableBody>
                    </Table>
                  </TableContainer>
                </Col>
                <Col xs={12} className="mb-5">
                  <TableContainer component={Paper}>
                    <Table aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell colSpan={2}>Status</TableCell>
                          <TableCell align="right">Station</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {all_status?.map(({ status, key }, a) => {
                          return (
                            <TableRow>
                              <TableCell>{a + 1}</TableCell>
                              <TableCell>{status}</TableCell>
                              <TableCell align="right">{key}</TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Col>
              </>
            )}
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Tracking;
