import React, { useEffect, useState } from "react";
import classes from "./SghTraining.module.css";
import {
  SghTrainingApi,
  SghTrainingPreviewApi,
  TrainingCoursesPreviewApi,
  TrainingCoursesApi,
} from "../../../../Services/SghTrainingService";
import { BLOCKS, MARKS, INLINES } from "@contentful/rich-text-types";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { NavLink, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import { Row, Container, Col } from "react-bootstrap";
import { useSelector } from "react-redux";
import "../../../../assets/mobirise/css/mbr-additional.css";
import "../../../../assets/theme/css/style.css";
import Slider from "../../../Slider/Slider";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import { Link } from "react-router-dom";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { PageTitle } from "../../../../utils/common";

const SghTraining = () => {
  const Dispatch = useDispatch();
  let location = useLocation();
  const url = useSelector((state) => state.url);
  const [sghTraining, setSghTraining] = useState(null);
  const [courses, setCourses] = useState(null);
  useEffect(async () => {
    let sghTrainingData;
    let courseData;
    const queryParams = new URLSearchParams(location.search);
    const val = queryParams.get("v") || "";
    if (val == "preview") {
      sghTrainingData = await SghTrainingPreviewApi(url);
      courseData = await TrainingCoursesPreviewApi(url);
    } else {
      sghTrainingData = await SghTrainingApi(url);
      courseData = await TrainingCoursesApi(url);
    }
    setSghTraining(sghTrainingData);
    setCourses(courseData);
    PageTitle(courseData?.Title);
  }, []);
  const HEADING_1 = ({ children }) => (
    <h1 className="mbr-section-title text-center mbr-fonts-style mbr-black mb-3 display-5">
      {children}
    </h1>
  );
  const HEADING_2 = ({ node, children }) => (
    <h2 className="mbr-fonts-style mbr-black mb-3 display-5">{children}</h2>
  );
  const HEADING_3 = ({ children }) => (
    <h3 className="mbr-fonts-style mbr-black mb-3 text-center display-7">
      {children}
    </h3>
  );

  const options = {
    renderNode: {
      [BLOCKS.HEADING_1]: (node, children) => <HEADING_1>{children}</HEADING_1>,
      [BLOCKS.HEADING_2]: (node, children) => <HEADING_2>{children}</HEADING_2>,
      [BLOCKS.HEADING_3]: (node, children) => <HEADING_3>{children}</HEADING_3>,
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <p className={paragraphClass(node)}>{children}</p>
      ),
    },
  };
  function paragraphClass(node) {
    const className = "mbr-fonts-style mbr-text mbr-white mb-3 display-7";
    return className;
  }
  let display = null,
    path = [];

  if (sghTraining) {
    display = sghTraining?.Description?.content?.map((info, id) => {
      return documentToReactComponents(info, options);
    });
    path = sghTraining?.Path.split("/");
    path = path.slice(0, path.length - 1);
    console.log("path ", path);
  }
  if (courses) {
    console.log(courses);
  }
  return (
    <>
      <Slider
        data={sghTraining?.PageImages}
        title={sghTraining?.Title}
      ></Slider>

      <section className={" text-left content5 centeradd cid-szWdSGiEXu"}>
        <Container>
          <Row>
            <Col xs="12" md="12">
              <div className={classes.breadcrumb + " mb-4"}>
                <div>
                  <NavLink to="/">HOME</NavLink>
                  <span className={classes.bread + " me-1 ms-1"}>
                    &gt;
                  </span>{" "}
                  {path?.map((m) => {
                    let pth = sghTraining?.Path.substring(
                      0,
                      sghTraining?.Path.indexOf(m) + m.length
                    );
                    return (
                      <>
                        <NavLink to={"/" + pth}>
                          {m.replace(/-/g, " ").toUpperCase()}
                        </NavLink>

                        <span className={classes.bread + " me-1 ms-1"}>
                          &gt;
                        </span>
                      </>
                    );
                  })}
                  {sghTraining?.Title}
                </div>
              </div>
              <h1 className="mbr-section-title mbr-fonts-style mbr-black mb-3 text-bold display-5">
                {sghTraining?.PageTitle}
              </h1>
              {display}
            </Col>
          </Row>

          <Row className="text-center">
            <Col xs={12} className="mb-5">
              <TableContainer component={Paper}>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        Course Name
                      </TableCell>
                      <TableCell>Location</TableCell>
                      <TableCell>Price</TableCell>
                      <TableCell>Date</TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {courses?.SubMenu?.map((info, id) => {
                      return (
                        <TableRow>
                          <TableCell>{info.fields.courseName}</TableCell>
                          <TableCell>{info.fields.location}</TableCell>
                          <TableCell>{info.fields.price}</TableCell>
                          <TableCell>
                            {info.fields.startDate.substring(0, 10)} -{" "}
                            {info.fields.endDate.substring(0, 10)}
                          </TableCell>
                          <TableCell>
                            <Link
                              to={{
                                pathname: "/registration-form",
                                state: {
                                  name: info.fields.courseName,
                                  location: info.fields.location,
                                  price: info.fields.price,
                                  start: info.fields.startDate,
                                  title: "Course Registration",
                                  end: info.fields.endDate,
                                },
                              }}
                              style={{
                                textDecoration: "none",
                              }}
                              target="_self"
                            >
                              Book
                            </Link>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default withRouter(SghTraining);
